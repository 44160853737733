







import { Vue } from 'vue-property-decorator';

export default class Analysis extends Vue {

}
